@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #f4f7fa;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  color: #1d2939;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
  color: var(--tg-theme-link-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

ul {
  list-style: none;
}

button {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.container-mobile {
  max-width: 640px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
}

.container {
  max-width: 1640px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
}

.form-error {
  color: red;
  font-size: 12px;
}

.status__approved {
  color: green;
}

.tag-black {
  color: black !important;
}

.button-green {
  color: green !important;

  &:focus {
    border: 1px solid green !important;
  }

  &:hover {
    border: 1px solid green !important;
  }
}

.status__waiting {
  color: gray;
}

.status__rejected {
  color: red;
}

.status__waiting-for-the-start {
  color: blue;
}

.pag-load {
  text-align: center;
  font-size: 14px;
  font-weight: 300;
}

.no-list {
  text-align: center;
  font-size: 16px;
  font-weight: 300;
}
//  AntDesign

// custom range picker

.range-table-free {
  // background-color: #05bf055c;
}

// end custom range picker

.ant-modal-title {
  padding-right: 14px;
}

.ant-form-item {
  margin-bottom: 20px !important;
}
.ant-form-item-control {
  flex: 1 !important;
}
.ant-form-item-label {
  padding-bottom: 5px !important;
}

.ant-picker-panels {
  flex-direction: column !important;
}

.ant-modal-wrap {
  overflow: hidden !important;
  overflow-y: scroll !important;
}

.ant-table-thead th {
  background: #ffffff !important;
}

.ant-tabs-nav-list {
  width: 100% !important;
}

.ant-picker-clear {
  display: none;
}

.ant-select-selection-item {
  /* width: 100%; */
  word-break: break-word;
  /* overflow: hidden; */
  white-space: break-spaces !important;
  height: 100%;
}

@media (max-width: 510px) {
  .ant-picker-datetime-panel {
    flex-direction: column;
  }
}

.ant-picker-range {
  width: 100%;
}

.ant-modal-confirm-btns {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 2px;
}

.ant-picker-header-view {
  display: flex;
  align-items: center;
  justify-content: center;
}
